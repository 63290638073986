import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Layout, AuthorCard } from '../components/common'
import { MetaData } from '../components/common/meta'

const Authors = ({data, location}) => {
  const authors = data.allGhostAuthor.edges
  return (
    <>
    <MetaData location={location} />
    <Layout isHeader='author'>
      <div className="container mb-5">
        <section className="post-feed authors mt-n11">
          {
            authors.map(({ node }, i) => (
              // The tag below includes the markup for each author - components/common/AuthorCard.js
              <AuthorCard key={i} author={node} />
            ))
          }
        </section>
      </div>
    </Layout> 
    </>
  )
}

Authors.propTypes = {
  data: PropTypes.shape({
    allGhostAuthor: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}

export default Authors

export const pageQuery = graphql`
    {
      allGhostAuthor {
          edges {
            node {
              id
              slug
              name
              bio
              profile_image
              cover_image
              postCount
            }
          }
        }
    }
`
